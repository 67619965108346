import React from 'react'
import MaskedInput from 'react-text-mask'

export const Input = ({ children, label = '', mask, color, ...props }) => {
  const themeColor = color ? `iliad_color-${color}` : '' // broken - passes hex color in but needs color name
  let inputType = <input {...props} className={themeColor} />
  if (mask) {
    inputType = <MaskedInput mask={mask} {...props} className={themeColor} />
  }

  return (
    <div className='input-container'>
      {inputType}
      <label
        htmlFor={props.id}
        className={themeColor}
        style={{ cursor: 'text' }}>
        <span style={{ pointerEvents: 'none' }}>{label}</span>
      </label>
    </div>
  )
}

export const TextArea = ({
  className = 'input-container',
  label = '',
  name = '',
  id = '',
  required = false,
  disabled = false,
  rows = 2,
  color = '#fafafa',
  ...props
}) => {
  return (
    <div className={className}>
      <textarea
        name={name}
        id={id}
        required={required}
        disabled={disabled}
        rows={rows}
        className={`iliad_color-${color}`}
        {...props}
      />
      <label htmlFor={id} className={`iliad_color-${color}`}>
        <span>{label}</span>
      </label>
    </div>
  )
}
