import React, { useState } from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { FaLongArrowAltRight, FaArrowDown } from 'react-icons/fa'
import * as styles from './Button.module.scss'

const ButtonIcon = ({ icon }) => {
  if (icon === 'right-arrow') {
    return <FaLongArrowAltRight />
  }
  if (icon === 'down-arrow') {
    return <FaArrowDown />
  }
  return null
}

export const Button = ({
  onClick,
  buttonText,
  buttonURL,
  buttonAction,
  colorTheme,
  nextSection,
  state,
  icon = 'right-arrow',
}) => {
  const {
    buttonColor,
    buttonHoverColor,
    buttonBorderColor,
    buttonBorderHoverColor,
    buttonBackgroundColor,
    buttonHoverBackgroundColor,
  } = colorTheme

  const [isHovered, setIsHovered] = useState(false)
  const onHover = () => setIsHovered(true)
  const onLeave = () => setIsHovered(false)

  const color = {
    color: buttonColor,
    border: `${buttonBorderColor} 0.125em solid`,
    backgroundColor: buttonBackgroundColor,
  }

  const hoverColor = {
    color: buttonHoverColor,
    border: `${buttonBorderHoverColor} 0.125em solid`,
    backgroundColor: buttonHoverBackgroundColor,
  }

  let buttonActionEnum
  if (buttonAction === 'scrollNext') {
    buttonActionEnum = () => scrollTo(nextSection)
  }
  if (buttonAction === 'scrollContact') {
    buttonActionEnum = () => scrollTo('#contact')
  }
  if (!buttonAction) {
    buttonActionEnum = () => null
  }

  if (!onClick && !buttonAction && buttonURL) {
    return (
      <Link
        to={buttonURL}
        className={styles.iliad__button}
        state={state && state}
        style={isHovered ? hoverColor : color}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}>
        {buttonText}
        <ButtonIcon icon='right-arrow' />
      </Link>
    )
  }
  return (
    <button
      onClick={onClick ? onClick : () => buttonActionEnum()}
      className={styles.iliad__button}
      style={isHovered ? hoverColor : color}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}>
      {buttonText}
      <ButtonIcon icon={icon} />
    </button>
  )
}
